const response: PackageItem[] = [
  {
    prodId: "3",
    prodNombre: "El Comercio",
    prodCodigo: "elcomercio",
    prodDescripcion: "DIARIO EL COMERCIO",
    prodOrden: "1",
    catId: "1",
    catNombre: "diarios",
    tisId: "424",
    tisPrecio: "24.00",
  },
  {
    prodId: "38",
    prodNombre: "Gesti\u00f3n",
    prodCodigo: "gestion",
    prodDescripcion: "DIARIO GESTI\u00d3N",
    prodOrden: "2",
    catId: "1",
    catNombre: "diarios",
    tisId: "425",
    tisPrecio: "42.00",
  },
  {
    prodId: "37",
    prodNombre: "Per\u00fa21",
    prodCodigo: "peru21",
    prodDescripcion: "DIARIO PER\u00da 21",
    prodOrden: "3",
    catId: "1",
    catNombre: "diarios",
    tisId: "423",
    tisPrecio: "20.00",
  },
  {
    prodId: "91",
    prodNombre: "Diario Correo",
    prodCodigo: "dcorreolima",
    prodDescripcion: "Diario Correo",
    prodOrden: "4",
    catId: "1",
    catNombre: "diarios",
    tisId: "418",
    tisPrecio: "20.00",
  },
  {
    prodId: "36",
    prodNombre: "Trome",
    prodCodigo: "trome",
    prodDescripcion: "DIARIO TROME",
    prodOrden: "5",
    catId: "1",
    catNombre: "diarios",
    tisId: "422",
    tisPrecio: "12.00",
  },
  {
    prodId: "113",
    prodNombre: "Diario Ojo",
    prodCodigo: "dojo",
    prodDescripcion: "Diario Ojo",
    prodOrden: "6",
    catId: "1",
    catNombre: "diarios",
    tisId: "421",
    tisPrecio: "12.00",
  },
  {
    prodId: "57",
    prodNombre: "Depor",
    prodCodigo: "depor",
    prodDescripcion: "DIARIO DEPOR",
    prodOrden: "7",
    catId: "1",
    catNombre: "diarios",
    tisId: "419",
    tisPrecio: "12.00",
  },
  {
    prodId: "132",
    prodNombre: "Revista Casas",
    prodCodigo: "rcasas",
    prodDescripcion: "Revista Casas",
    prodOrden: "25",
    catId: "2",
    catNombre: "revistas",
    tisId: "394",
    tisPrecio: "8.00",
  },
  {
    prodId: "135",
    prodNombre: "Revista Padres",
    prodCodigo: "rpadres",
    prodDescripcion: "Revista Padres",
    prodOrden: "28",
    catId: "2",
    catNombre: "revistas",
    tisId: "395",
    tisPrecio: "8.00",
  },
  {
    prodId: "139",
    prodNombre: "Revista Miner\u00eda & Energ\u00eda",
    prodCodigo: "rmineriayenergia",
    prodDescripcion: "Revista Miner\u00eda & Energ\u00eda",
    prodOrden: "28",
    catId: "2",
    catNombre: "revistas",
    tisId: "397",
    tisPrecio: "15.00",
  },
  {
    prodId: "145",
    prodNombre: "Revista Arq",
    prodCodigo: "rarq",
    prodDescripcion: "Revista Arq",
    prodOrden: "28",
    catId: "2",
    catNombre: "revistas",
    tisId: "400",
    tisPrecio: "15.00",
  },
  {
    prodId: "148",
    prodNombre: "Revista B\u00d6RBON",
    prodCodigo: "rborbon",
    prodDescripcion: "Revista B\u00d6RBON",
    prodOrden: "28",
    catId: "2",
    catNombre: "revistas",
    tisId: "404",
    tisPrecio: "10.00",
  },
  {
    prodId: "149",
    prodNombre: "Revista LUHHO",
    prodCodigo: "rluhho",
    prodDescripcion: "Revista LUHHO",
    prodOrden: "28",
    catId: "2",
    catNombre: "revistas",
    tisId: "405",
    tisPrecio: "10.00",
  },
  {
    prodId: "150",
    prodNombre: "Revista TIEMPO DE LUHHO",
    prodCodigo: "rtiempoluhho",
    prodDescripcion: "Revista TIEMPO DE LUHHO",
    prodOrden: "28",
    catId: "2",
    catNombre: "revistas",
    tisId: "406",
    tisPrecio: "8.00",
  },
  {
    prodId: "151",
    prodNombre: "Revista Hombre",
    prodCodigo: "rhombre",
    prodDescripcion: "Revista Hombre",
    prodOrden: "28",
    catId: "2",
    catNombre: "revistas",
    tisId: "408",
    tisPrecio: "10.00",
  },
  {
    prodId: "152",
    prodNombre: "Coleccionable Exportador 1",
    prodCodigo: "cexportador1",
    prodDescripcion: "Coleccionable Exportador 1",
    prodOrden: "28",
    catId: "2",
    catNombre: "revistas",
    tisId: "409",
    tisPrecio: "6.00",
  },
];

export interface PackageItem {
  prodId: string;
  prodNombre: string;
  prodCodigo: string;
  prodDescripcion: string;
  prodOrden: string;
  catId: string;
  catNombre: string;
  tisId: string;
  tisPrecio: string;
}

export interface Packages {
  categorie: string;
  options: PackageItem[];
}

const getPackage = async (): Promise<Packages[]> => {
  const response = await fetch(process.env.REACT_APP_PACKAGE_SERVICE, {
    method: "GET",
  });

  const result = await response.json();

  const categorize = (prev: Packages[], packageItem: PackageItem) => {
    const { catNombre } = packageItem;
    const currentPackageItem = prev.find(
      ({ categorie }) => categorie === catNombre
    );

    if (currentPackageItem) {
      currentPackageItem.options = [...currentPackageItem.options, packageItem];
    } else {
      prev = [...prev, { categorie: catNombre, options: [packageItem] }];
    }
    return prev;
  };

  const toCamelCase = ({
    tis_precio,
    prod_id,
    prod_nombre,
    cat_nombre,
    catId,
    prod_codigo,
    prod_descripcion,
    prod_orden,
    tis_id,
  }: any): PackageItem => ({
    tisPrecio: tis_precio,
    prodId: prod_id,
    prodNombre: prod_nombre,
    catNombre: cat_nombre,
    catId: catId,
    prodCodigo: prod_codigo,
    prodDescripcion: prod_descripcion,
    prodOrden: prod_orden,
    tisId: tis_id,
  });

  if (response.ok) {
    return result.map(toCamelCase).reduce(categorize, []);
  }

  throw { error: "", message: result.message };
};

export const services = {
  getPackage,
};
