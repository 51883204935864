import React, { ChangeEvent, useEffect, useState } from "react";
import CheckBox from "../../components/CheckBox/CheckBox";
import "./Individual.scss";
import Section from "../../components/Section/Section";
import Text from "../../components/Text/Text";
import Button from "../../components/Button/Button";
import { Packages, services } from "../../services/services";
import Skeleton, { Shape, Size } from "../../components/Skeleton/Skeleton";

const packageList = new Map();

const Individuals: React.FC = () => {
  const [packages, setPackages] = useState<Packages[]>([]);
  const [source, setSource] = useState<MessageEventSource | null>(null);
  const [origin, setOrigin] = useState("");
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    window.addEventListener("message", (event: MessageEvent) => {
      if (event.data === "broadcast" && event.source) {
        setSource(event.source);
        setOrigin(event.origin);

        const message = JSON.stringify({ response: "ready" });
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        event.source.postMessage(message, event.origin);
      }
    });
  }, []);

  useEffect(() => {
    const getPackage = async () => {
      setLoading(true);
      const packages = await services.getPackage();
      setPackages(packages);
      setLoading(false);
    };

    getPackage();
  }, []);

  const onSubmit = () => {
    setDisabled(true);
    const products = [...packageList].map(([productId, tisId]) => ({
      productId,
      tisId,
    }));
    if (source) {
      const message = JSON.stringify({ response: "products", products });
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      source.postMessage(message, origin);
    }
  };

  return (
    <div className="individual-page">
      <div className="individual-page__head">
        <span className="individual-page__head__title">
          Seleccionar las opciones
        </span>
      </div>

      {loading ? (
        <>
          <Skeleton lines={1} shape={Shape.Round} size={Size.Large} />
          <br />
          <Skeleton lines={6} shape={Shape.Round} />
          <br />
          <br />
          <Skeleton lines={1} shape={Shape.Round} size={Size.Large} />
          <br />
          <Skeleton lines={6} shape={Shape.Round} />
        </>
      ) : (
        packages.map(({ categorie, options }) => (
          <div className="individual-page__card" key={categorie}>
            <Section key={categorie} title={categorie} />
            {options.map(({ prodId, prodNombre, tisPrecio, tisId }, index) => (
              <CheckBox
                onChange={({ target }: ChangeEvent<HTMLInputElement>) => {
                  if (target.checked) {
                    setTotal(total + +tisPrecio);
                    packageList.set(prodId, tisId);
                  } else {
                    packageList.delete(prodId);
                    setTotal(total - +tisPrecio);
                  }
                  setDisabled(packageList.size === 0);
                }}
                id={prodId}
                label={<Text>{`${prodNombre} - S/. ${tisPrecio} al mes`}</Text>}
                key={`${prodId}-${index}`}
              />
            ))}
          </div>
        ))
      )}

      <div className="divider" />

      <Text>{`Total: S/. ${total.toFixed(2)}`}</Text>

      <Button disabled={disabled} onSubmit={onSubmit}>
        <Text>Comprar</Text>
      </Button>
    </div>
  );
};

export default Individuals;
