import React, { ChangeEventHandler, ReactNode } from "react";
import "./CheckBox.scss";

interface CheckBoxProps {
  id: string;
  label: ReactNode;
  className?: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
}

const CheckBox: React.FC<CheckBoxProps> = ({
  id,
  label,
  onChange,
}: CheckBoxProps) => {
  return (
    <div className="checkbox-component">
      <input type="checkbox" id={id} name={id} onChange={onChange} />
      <label htmlFor={id}>{label}</label>
    </div>
  );
};

export default CheckBox;
