import React from "react";
import "./Layout.scss";

interface IndividualProps {
  children: React.ReactNode;
}

const Individual: React.FC<IndividualProps> = ({
  children,
}: IndividualProps) => {
  return <div className="layout-page">{children}</div>;
};

export default Individual;
