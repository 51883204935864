import React from "react";
import "./Section.scss";

interface SectionProps {
  title: string;
}

const Section: React.FC<SectionProps> = ({ title }: SectionProps) => {
  return <section className="section">{title}</section>;
};

export default Section;
