import React, { MouseEventHandler, ReactNode } from "react";
import "./Button.scss";

interface ButtonProps {
  children: ReactNode;
  className?: string;
  onSubmit?: MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
}

const Button: React.FC<ButtonProps> = ({
  children,
  onSubmit,
  disabled,
}: ButtonProps) => {
  return (
    <button className="button-component" disabled={disabled} onClick={onSubmit}>
      {children}
    </button>
  );
};

export default Button;
