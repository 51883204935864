import React from "react";
import "./Text.scss";

interface TextProps {
  children: string;
}

const Text: React.FC<TextProps> = ({ children }: TextProps) => {
  return <span className="text-component">{children}</span>;
};

export default Text;
